<template>
  <div>
    <NCard size="small" :border="false" :segmented="{ content: 'hard' }" :title="`上传${teachingTypeName}`">
      <NForm
        ref="formRef"
        label-placement="left"
        :label-width="120"
        require-mark-placement="left"
        :model="formValues"
        :rules="formRules"
      >
        <NFormItem label="标题：" path="title">
          <NInput
            style="width: 300px;"
            maxlength="50"
            :placeholder="`请输入${teachingTypeName}标题`"
            show-count
            clearable
            v-model:value="formValues.title"
          />
        </NFormItem>
        <NFormItem label="添加文件：" path="file_url">
          <AddLocalFile
            ref="addLocalFileRef"
            :max-num="maxFileNum"
            :accept="fileAccept"
            :accept-size-txt="fileAcceptSizeTxt"
            @file-list-change="handleUpFileChange"
            :init-list="initUploadList"
          />
        </NFormItem>
        <NFormItem label="标签：" path="label_ids">
          <ItemAddLabel
            ref="itemAddLabelRef"
            :label-type="resourceEnum.TEACHING_RESOURCE_TYPE"
            @list-update="handleLabelChange"
          />
        </NFormItem>
      </NForm>
      <div style="margin-top: 20px;">
        <NSpace>
          <NButton @click="handleBack">返回</NButton>
          <NButton type="primary" @click="handleSave">保存</NButton>
        </NSpace>
      </div>
    </NCard>

    <BeforeSubmitUpload
      ref="beforeSubmitUploadRef"
      @all-file-success="submitFormValues"
      @upload-error="handleUploadError"
    />

    <PageLoading :loading="loading" />
  </div>
</template>

<script setup>
  import { ref, reactive } from 'vue';
  import { useRouter, useRoute } from 'vue-router';
  import { useMessage } from 'naive-ui';

  import AddLocalFile from '@/components/AddLocalFile/AddLocalFile.vue';
  import BeforeSubmitUpload from '@/components/BeforeSubmitUpload/BeforeSubmitUpload.vue';
  import ItemAddLabel from '@/components/ItemAddLabel/ItemAddLabel.vue';
  import PageLoading from '@/components/PageLoading/index.vue';

  import teachingTypesMap, { teachingTypeEnum } from '@/enumerators/teaching-types-map.js';
  import { resourceEnum } from '@/enumerators/resource-types-map.js';
  import { createTeaching, getTeachingDetail, updateTeaching } from '@/api/teaching.js';
  import { PostHomeIndex } from '@/api/admin-home.js';
  import { resStatusEnum } from '@/enumerators/http.js';

  
  const { SUCCESS } = resStatusEnum;
  const message = useMessage();
  const loading = ref(false);

  const addLocalFileRef = ref(null);

  const route = useRoute();
  const router = useRouter();
  const teachingType = route.query.type;
  const teachingId = route.query.id;
  const teachingTypeName = teachingTypesMap[teachingType];

  const fileAccept = (() => {
    switch (Number(teachingType)) {
      case teachingTypeEnum.PPT:
        return '.pdf';
      case teachingTypeEnum.VIDEO:
        return '.mp4,.mp3';
      case teachingTypeEnum.IMG:
        return '.jpg,.jpeg,.png,.gif,.bmp';
    }
  })();
  const fileAcceptSizeTxt = (() => {
    switch (Number(teachingType)) {
      case teachingTypeEnum.PPT:
        return '单文件大小不超过 50MB';
      case teachingTypeEnum.VIDEO:
        return '单文件大小不超过 2GB'; 
      case teachingTypeEnum.IMG:
        return '单文件大小不超过 5MB';
    }
  })();
  const maxFileNum = (() => {
    switch (Number(teachingType)) {
      case teachingTypeEnum.PPT:
      case teachingTypeEnum.VIDEO:
        return 1;
      case teachingTypeEnum.IMG:
        return 20;
    }
  })();

  const isEdit = !!teachingId;

  const formRef = ref(null);
  const resReqParamsMap = isEdit ? {
    title: 'Teaching[title]', // String
    label_ids: 'Teaching[end_label]', // Array
    id: 'Teaching[id]', // String
    type: 'Teaching[type]', // String 教材类型(1讲义，2音视频，3图片)
    delete_uploads: 'Teaching[delete_uploads]', // Array
    upload: 'Teaching[add_upload]' // Array
  } : {
    title: 'Teaching[title]', // String
    type: 'Teaching[type]', // String 教材类型(1讲义，2音视频，3图片)
    label_ids: 'Teaching[label]', // Array
    upload: 'Teaching[upload]' // Array
  };
  const uploadParamsMap = {
    fileUrl: 'file_url',
    name: 'file_name',
    size: 'file_size',
    ext: 'extension',
    fileType: 'file_type',
    videoId: 'video_id'
  };
  const getDefaultFormValues = () => {
    const tempObj = {};
    Object.keys(resReqParamsMap).forEach(key => {
      switch (key) {
        case 'label_ids':
        case 'delete_uploads':
        case 'upload':
          tempObj[key] = [];
          break;
        case 'type':
          tempObj[key] = teachingType;
          break;
        default:
          tempObj[key] = '';
      }
    });
    return tempObj;
  };
  const formValues = reactive(getDefaultFormValues());
  const formRules = {
    title: {
      required: true,
      message: '必填',
      trigger: 'blur'
    },
    file_url: {
      key: 'file_url',
      required: true,
      validator: (rule, value) => {
        if (addLocalFileRef.value.getFileList().length > 0) {
          return true;
        } else {
          return new Error('请添加文件');
        }
      }
    },
    label_ids: {
      key: 'label_ids',
      required: true,
      validator: (rule, value) => {
        if (selectedLabels.value.length > 0) {
          return true;
        } else {
          return new Error('请添加标签');
        }
      }
    }
  };

  const selectedLabels = ref([]);
  const handleLabelChange = selectedLabelsArr => {
    selectedLabels.value = selectedLabelsArr;
    triggerLabelValidator();
  };
  const triggerLabelValidator = () => {
    formRef.value.validate(errors => {
    }, rule => {
      return rule.key === 'label_ids';
    });
  };

  const handleUpFileChange = () => {
    formRef.value.validate(errors => {
    }, rule => {
      return rule.key === 'file_url';
    });
  };

  const handleBack = () => {
    router.push('/teaching/index/list');
  };
  const handleSave = () => {
    formRef.value.validate(errors => {
      if (!errors) {
        /*
        [
          []
        ]
        */
        const fileList = [
          addLocalFileRef.value.getFileList()
        ];
        beforeSubmitUploadRef.value.startUpload(fileList);
      }
    });
    return false;
  };
  const submitFormValues = fileList => {
    loading.value = true;
    const reqData = {};
    let reqFn = null;
    let successMessage = '';
    Object.keys(resReqParamsMap).forEach(key => {
      switch (key) {
        case 'label_ids':
        case 'delete_uploads':
        case 'upload':
          break;
        default:
          reqData[resReqParamsMap[key]] = formValues[key];
      }
    });
    reqData[resReqParamsMap['label_ids']] = selectedLabels.value.map(item => item.id);
    if (isEdit) {
      reqFn = updateTeaching;
      successMessage = '编辑成功';
      
      reqData[resReqParamsMap['upload']] = fileList[0].filter(item => !item.savedId).map(item => {
        const tempItem = {};
        Object.keys(uploadParamsMap).forEach(key => {
          switch (key) {
            case 'videoId':
              item[key] && (tempItem[uploadParamsMap[key]] = item[key]);
              break;
            default:
              tempItem[uploadParamsMap[key]] = item[key];
          }
        });
        return tempItem;
      });
      reqData[resReqParamsMap['delete_uploads']] = getDelFileIds(savedFileIdArr, fileList[0].filter(item => !!item.savedId).map(item => item.savedId));
    } else {
      reqFn = createTeaching;
      successMessage = '新增成功';
      reqData[resReqParamsMap['upload']] = fileList[0].map(item => {
        const tempItem = {};
        Object.keys(uploadParamsMap).forEach(key => {
          switch (key) {
            case 'videoId':
              item[key] && (tempItem[uploadParamsMap[key]] = item[key]);
              break;
            default:
              tempItem[uploadParamsMap[key]] = item[key];
          }
        });
        return tempItem;
      });
    }
    reqFn(reqData).then(res => {
      if (res.code === SUCCESS) {
        message.success(successMessage);
        handleBack();
      }
    }).catch(err => {}).finally(() => {
      loading.value = false;
    });
  };
  const handleUploadError = () => {
    console.log('有文件上传失败，返回');
  };

  const beforeSubmitUploadRef = ref(null);

  // 编辑

  const initUploadList = ref([]);
  const savedFileIdArr = [];
  const getDelFileIds = (srcIdArr, curIdArr) => {
    const tempArr = [];
    srcIdArr.forEach(item => {
      curIdArr.includes(item) || tempArr.push(item);
    });
    return tempArr;
  };

  const itemAddLabelRef = ref(null);
  if (isEdit) {
    loading.value = true;
    getTeachingDetail({
      'Teaching[id]': teachingId
    }).then(res => {
      if (res.code === SUCCESS) {
        const resData = res.data;
        Object.keys(formValues).forEach(key => {
          switch (key) {
            case 'id':
              formValues[key] = teachingId;
              break;
            case 'type':
            case 'delete_upload':
              break;
            case 'upload':
              if (Array.isArray(resData.source)) {
                initUploadList.value = resData.source.map(item => ({
                  id: item.id,
                  savedId: item.id,
                  name: item.file_name,
                  status: 'pending',
                  file: {
                    size: Number(item.file_size)
                  },
                  saved: true,
                  fileUrl: item.file_url
                }));
                savedFileIdArr.splice(0);
                savedFileIdArr.push(...resData.source.map(item => item.id));
              }
              break;
            case 'label_ids':
              if (Array.isArray(resData.label_list)) {
                const labels = resData.label_list.filter(item => resData.label_ids.includes(item.id));
                selectedLabels.value = labels;
                itemAddLabelRef.value.setSelectedLabels(labels);
              }
              break;
            default:
              formValues[key] = resData[key];
          }
        });
      }
    }).catch(err => {}).finally(() => {
      loading.value = false;
    });
  }

  // 在当前页面时 每半小时请求接口 刷新验签 防止登录过期
  setInterval(function(){
    PreventExpiration()
  },1800000)
  function PreventExpiration(){
    PostHomeIndex().then(res=>{
    }).catch(err=>{})
  }
  PreventExpiration()
</script>